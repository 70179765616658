export const people = [
  {
    id: 1,
    name: "Faisal Iqbal",
    image: "/images/persons/1.jpg",
  },
  {
    id: 2,
    name: "Umair Waheed",
    image: "/images/persons/1.jpg",
  },
  {
    id: 3,
    name: "Salman Shabbir",
    image: "/images/persons/1.jpg",
  },
  {
    id: 4,
    name: "Gul Faraz Ahmad",
    image: "/images/persons/1.jpg",
  },
  {
    id: 5,
    name: "Talha Ihsan",
    image: "/images/persons/1.jpg",
  },
];

